
import { EventBus } from "@/plugins/eventBus"
import axios, { AxiosError, AxiosResponse } from "axios"
import Vue from "vue"
export default Vue.extend({
    name: "filesMixin",
    methods: {
        launchFileDownload: function (url: string): void {
            axios.get(url, { responseType: 'blob' })
            .then((response: AxiosResponse) => {
                // Create a new Blob from the response of the apu
                const blob = new Blob([response.data], { type: response.headers.type })
                // Create an element containing an URL representing the Blob
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                // Set up the right headers and assign the file name
                let headerLine = response.request.getResponseHeader('Content-Disposition')
                let startFileNameIndex = headerLine.indexOf('"') + 1
                let endFileNameIndex = headerLine.lastIndexOf('"')
                let fileName = headerLine.substring(startFileNameIndex, endFileNameIndex)
                // Set up the download
                link.download = fileName
                link.click()
                // Cleanup 
                URL.revokeObjectURL(link.href)
            }).catch((error: AxiosError) => {
                EventBus.$emit('snackbar',{axiosError: error})
            })
        }
    }
})
